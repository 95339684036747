import React from "react";
import Footer from "../components/Footer";

const Home = () => {
  return (
    <div className="relative px-6 lg:px-8">
      <div className="mx-auto max-w-2xl pt-28">
        <div className="hidden sm:mb-8 sm:flex sm:justify-center">
          <div className="relative rounded-full px-3 py-1 text-sm leading-6 text-gray-600 ring-1 ring-gray-900/10 hover:ring-gray-900/20">
            Announcing our next round of funding.{" "}
            <a href="#" className="font-semibold text-[#003C43]">
              <span className="absolute inset-0" aria-hidden="true" />
              Read more <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
        <div className="text-center">
          <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
            Seamless{" "}
            <span className="bg-gradient-to-r from-orange-700 via-blue-500 to-green-400 text-transparent bg-clip-text animate-gradient bg-300%">
              Collaboration
            </span>{" "}
            Realtime. Anywhere.
          </h1>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Bring collaboration to developers. We supports real-time
            collaboration for power users, new developers, and everyone in
            between.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <a
              href="#"
              className="rounded-3xl bg-[#003C43] px-10 py-4 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started for Free &#160; &rarr;
            </a>
          </div>
        </div>
      </div>
      {/* 3 features row */}
      <div className="mx-auto  max-w-2xl hidden sm:flex items-center justify-between pt-16 divide-x-2">
        <div className="flex-1">Realtime Collaboration</div>
        <div className="flex-1">Works with any language</div>
        <div className="flex-1">Live chat option</div>
      </div>
      {/* Feature tab */}
      <div className="mx-auto max-w-[1028px] h-[589px] border mt-20 rounded-3xl bg-gradient-to-r from-[#003C43] to-green-200"></div>
      {/* Realtime collaboration feature */}
      <div className="max-w-2xl mx-auto pt-20">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Real time Collaboration.
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Bring collaboration to developers. We supports real-time
            collaboration for power users, new developers, and everyone in
            between.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-[1028px] h-[589px] border mt-14 rounded-3xl bg-gray-500"></div>
      {/* Chat feature */}
      <div className="max-w-2xl mx-auto pt-20">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Eat. Chat & Code. Repeat.
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Bring collaboration to developers. We supports real-time
            collaboration for power users, new developers, and everyone in
            between.
          </p>
        </div>
      </div>
      <div className="mx-auto max-w-[1028px] h-[589px] border mt-14 rounded-3xl bg-gray-500"></div>
      {/* Get started button */}
      <div className="max-w-2xl mx-auto pt-20">
        <div className="text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Ready to take-off?
          </h2>
          <div className="mt-6 flex items-center justify-center gap-x-6">
            <a
              href="#"
              className="rounded-3xl bg-[#003C43] px-10 py-4 text-sm font-semibold text-white shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
            >
              Get started for Free
            </a>
          </div>
        </div>
      </div>
      {/* footer */}
      <Footer />
      {/* Made by */}
      <div className="text-center mb-5" role="img" aria-label="love">
        Made with &#10084;&#65039; by{" "}
        <a href="https://github.com/vinulays">Vinula Senarathne</a>
      </div>
    </div>
  );
};

export default Home;
