import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mx-auto w-full max-w-screen-xl pt-6 lg:pt-20 pb-12">
      <div className="md:flex md:justify-between">
        <div className="flex-col">
          <div className="mb-6 md:mb-0 mt-4 sm:mt-0">
            <Link to="/" className="flex items-center">
              <span className="self-center text-2xl font-semibold whitespace-nowrap ">
                Collaby.io
              </span>
            </Link>
          </div>
          <div className="mt-3 mb-6 max-w-[500px]">
            <p className="text-left">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
              ornare cursus sed nunc eget dictum Sed ornare cursus sed nunc eget
              dictumd nunc eget dictum Sed ornare cursus sed nunc eget dictum
            </p>
          </div>
        </div>

        <div className="grid grid-cols-2 gap-8 sm:gap-20 sm:grid-cols-2 text-left">
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase ">
              Company
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-4">
                <a href="https://flowbite.com/" className="hover:underline">
                  Pricing
                </a>
              </li>
              <li className="mb-4">
                <a href="https://tailwindcss.com/" className="hover:underline">
                  About US
                </a>
              </li>
              <li>
                <a href="https://tailwindcss.com/" className="hover:underline">
                  Support
                </a>
              </li>
            </ul>
          </div>
          <div>
            <h2 className="mb-6 text-sm font-semibold text-gray-900 uppercase">
              Legal
            </h2>
            <ul className="text-gray-500 dark:text-gray-400 font-medium">
              <li className="mb-4">
                <a href="#" className="hover:underline">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="#" class="hover:underline">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
